import { showAlert } from "../../util/alert.services";

import { api } from "../../util/config";
import { headers } from "../../util/config";

const axios = require("axios");

const signInWithEmailAndPassword = async (mobile, pin) => {
  try {
    let response = await axios.post(
      api.adminLogin,
      { mobile, pin, user_type: "admin" },
      headers
    );
    console.log(
      "🚀 ~ file: AuthApi.js:13 ~ signInWithEmailAndPassword ~ response:",
      response
    );
    return response.data.data ? response.data.data : {};
  } catch (error) {
    if (error.response) {
      showAlert(error.response.data.message, "e");
      //throw (error.error.response.data.message);
    }
  }
};

export { signInWithEmailAndPassword };
