import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}home`}
        component={asyncComponent(() => import("./HomePage"))}
      />

      <Route
        path={`${match.url}food_trucks`}
        component={asyncComponent(() => import("./CoursePage"))}
      />

      <Route
        path={`${match.url}orders`}
        component={asyncComponent(() => import("./OrderPage"))}
      />

      <Route
        path={`${match.url}category`}
        component={asyncComponent(() => import("./CategoryPage"))}
      />

      <Route
        path={`${match.url}sub-category`}
        component={asyncComponent(() => import("./SubCategoryPage"))}
      />

      <Route
        path={`${match.url}menus`}
        component={asyncComponent(() => import("./MenuItemPage"))}
      />

      <Route
        path={`${match.url}extra-items`}
        component={asyncComponent(() => import("./ExtraItemPage"))}
      />

      <Route
        path={`${match.url}extra-sub-items`}
        component={asyncComponent(() => import("./ExtraSubItemPage"))}
      />

      <Route
        path={`${match.url}promo-code`}
        component={asyncComponent(() => import("./PromocodePage"))}
      />
    </Switch>
  </div>
);

export default App;
