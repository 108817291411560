import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 2000,
});

export const showAlert = (message, type = "s") => {
  if (type === "s") {
    toast.success(message);
  } else if (type === "e") {
    toast.error(message);
  } else if (type === "w") {
    toast.warn(message);
  }
};
