// const APIV1 = "https://api.2yourcart.com/api";

// // Set Application Key Here
// const APP_KEY = "6b8c1d10-b72c-49b5-8619-b7ec62b91f07";

// let headers = {
//   headers: {
//     "Content-Type": "application/json; charset=UTF-8",
//     "app-key": `${APP_KEY}`,
//   },
// };

// module.exports = {
//   name: "App Admin",
//   prefix: "jumboAnt",
//   footerText: `Copyright coffee Lab © ${new Date().getFullYear()}`,
//   logo: "/logo.svg",
//   iconFontCSS: "/iconfont.css",
//   iconFontJS: "/iconfont.js",
//   CORS: [],
//   openPages: ["/login"],
//   apiPrefix: "/api/v1",
//   APIV1,
//   api: {
//     adminLogin: `${APIV1}/admin/login`,
//     adminDashboard: `${APIV1}/admin/dashboard`,

//     // Orders
//     listOrders: `${APIV1}/admin/order-list`,
//     listOrdersById: `${APIV1}/admin/order-detail`,

//     // Restaurant
//     listRestaurants: `${APIV1}/admin/restaurant-list`,
//     addRestaurants: `${APIV1}/restaurants`,
//     updateRestaurants: `${APIV1}/restaurants/update`,
//     deleteRestaurants: `${APIV1}/restaurants/delete`,

//     // Promo-code
//     listPromocode: `${APIV1}/promocode`,
//     addPromocode: `${APIV1}/promocode`,
//     updatePromocode: `${APIV1}/promocode/update`,
//     deletePromocode: `${APIV1}/promocode/delete`,

//     // Category
//     listCategory: `${APIV1}/category`,
//     listAllCategory: `${APIV1}/category`,
//     deleteCategory: `${APIV1}/category/delete`,
//     updateCategory: `${APIV1}/category/update`,
//     addCategory: `${APIV1}/category`,

//     // SubCategory
//     listSubCategory: `${APIV1}/admin/subcategory-list`,
//     deleteSubCategory: `${APIV1}/sub_category/delete`,
//     updateSubCategory: `${APIV1}/sub_category/update`,
//     addSubCategory: `${APIV1}/sub_category`,

//     // MenuItem
//     //listMenuItem: `${APIV1}/menus`,
//     listMenuItem: `${APIV1}/admin/menu-items-list`,
//     deleteMenuItem: `${APIV1}/menus/delete`,
//     updateMenuItem: `${APIV1}/menus/update`,
//     addMenuItem: `${APIV1}/menus`,

//     // ExtraItem
//     listExtraItem: `${APIV1}/extra_item`,
//     listExtraItemAdmin: `${APIV1}/admin/extra-items-list`,
//     deleteExtraItem: `${APIV1}/extra_item/delete`,
//     updateExtraItem: `${APIV1}/extra_item/update`,
//     addExtraItem: `${APIV1}/extra_item`,

//     // ExtraSubItem
//     listExtraSubItem: `${APIV1}/extra_sub_item`,
//     deleteExtraSubItem: `${APIV1}/extra_sub_item/delete`,
//     updateExtraSubItem: `${APIV1}/extra_sub_item/update`,
//     addExtraSubItem: `${APIV1}/extra_sub_item`,

//     // City
//     listCity: `${APIV1}/city`,
//     updateCity: `${APIV1}/city/update`,

//     //Cuisine
//     listCuisine: `${APIV1}/cuisine`,
//     updateCity: `${APIV1}/cuisine/update`,

//     userLogout: `${APIV1}/user/logout`,
//     userInfo: `${APIV1}/userInfo`,
//     users: `${APIV1}/users`,
//   },
//   headers: headers,

// getHeader: () => {
//   let token = localStorage.getItem("token");
//   return {
//     headers: {
//       "Content-Type": "application/json; charset=UTF-8",
//       "app-key": `${APP_KEY}`,
//       Authorization: `Bearer ${token}`,
//     },
//   };
// },
// };

export const APIV1 = "https://api.2yourcart.com/api";
export const APP_KEY = "6b8c1d10-b72c-49b5-8619-b7ec62b91f07";

export const headers = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "app-key": APP_KEY,
  },
};

export const name = "App Admin";
export const prefix = "jumboAnt";
export const footerText = `Copyright Alchemist Coffee Lab © ${new Date().getFullYear()}`;
export const logo = "/logo.svg";
export const iconFontCSS = "/iconfont.css";
export const iconFontJS = "/iconfont.js";
export const CORS = [];
export const openPages = ["/login"];
export const apiPrefix = "/api/v1";

export const api = {
  adminLogin: `${APIV1}/admin/login`,
  adminDashboard: `${APIV1}/admin/dashboard`,
  listOrders: `${APIV1}/admin/order-list`,
  listOrdersById: `${APIV1}/admin/order-detail`,
  listRestaurants: `${APIV1}/admin/restaurant-list`,
  addRestaurants: `${APIV1}/restaurants`,
  updateRestaurants: `${APIV1}/restaurants/update`,
  deleteRestaurants: `${APIV1}/restaurants/delete`,
  listPromocode: `${APIV1}/promocode`,
  addPromocode: `${APIV1}/promocode`,
  updatePromocode: `${APIV1}/promocode/update`,
  deletePromocode: `${APIV1}/promocode/delete`,
  listCategory: `${APIV1}/category`,
  listAllCategory: `${APIV1}/category`,
  deleteCategory: `${APIV1}/category/delete`,
  updateCategory: `${APIV1}/category/update`,
  addCategory: `${APIV1}/category`,
  listSubCategory: `${APIV1}/admin/subcategory-list`,
  deleteSubCategory: `${APIV1}/sub_category/delete`,
  updateSubCategory: `${APIV1}/sub_category/update`,
  addSubCategory: `${APIV1}/sub_category`,
  listMenuItem: `${APIV1}/admin/menu-items-list`,
  deleteMenuItem: `${APIV1}/menus/delete`,
  updateMenuItem: `${APIV1}/menus/update`,
  addMenuItem: `${APIV1}/menus`,
  listExtraItem: `${APIV1}/extra_item`,
  listExtraItemAdmin: `${APIV1}/admin/extra-items-list`,
  deleteExtraItem: `${APIV1}/extra_item/delete`,
  updateExtraItem: `${APIV1}/extra_item/update`,
  addExtraItem: `${APIV1}/extra_item`,
  listExtraSubItem: `${APIV1}/extra_sub_item`,
  deleteExtraSubItem: `${APIV1}/extra_sub_item/delete`,
  updateExtraSubItem: `${APIV1}/extra_sub_item/update`,
  addExtraSubItem: `${APIV1}/extra_sub_item`,
  listCity: `${APIV1}/city`,
  updateCity: `${APIV1}/city/update`,
  listCuisine: `${APIV1}/cuisine`,
  updateCuisine: `${APIV1}/cuisine/update`,
  userLogout: `${APIV1}/user/logout`,
  userInfo: `${APIV1}/userInfo`,
  users: `${APIV1}/users`,
};

export const getHeader = () => {
  let token = localStorage.getItem("token");
  return {
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      "app-key": `${APP_KEY}`,
      Authorization: `Bearer ${token}`,
    },
  };
};
